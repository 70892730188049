<template>
  <div
    v-if="provider && textAsync"
    class="pn-main d-flex flex-column gray-p--bg"
  >
    <div
      class="d-flex flex-column white--bg"
      :class="{ 'gray-p--bg': houseUrl && providersIds && providersIds.lengthl }"
    >
      <DHatPartnerNew
        :loading-providers="loadingProviders"
        :crumbs-items="crumbsItems"
        :providers-ids="providersIds"
        :h1="h1"
      />
    </div>
    <LazyTariffsPageTariffsWrapperPartners
      v-if="tariffs"
      ref="tariffsWrap"
      v-model:filters="filters"
      :ranges="ranges"
      :location-agg="tariffsAgg ? tariffsAgg.locationAgg : undefined"
      :tariffs-agg="tariffsAgg"
      :tariffs-in-house="!!providersIds?.length"
      :tariffs="tariffs"
    />
    <LazyPartnersActiveTariffs v-if="tariffs?.data?.[0]?.markers?.fake" />
    <LazyBlockCities
      title="Подключаем проводной интернет по всей России"
      :cities="cities"
      :provider="provider.provider"
    />

    <LazyPartnersHowConnect :call-number="callNumber" />

    <LazyPartnersPromoSwiper
      v-if="
        textAsync
          && textAsync.blocks
          && textAsync.blocks.promotions
          && textAsync.blocks.promotions.length
      "
      :provider="provider"
      :promotions="textAsync.blocks.promotions"
    />

    <LazyPartnersMainBonus
      v-if="
        textAsync
          && textAsync.blocks
          && textAsync.blocks.additional_services
          && textAsync.blocks.additional_services.length
      "
      :bonus="textAsync.blocks.additional_services"
    />

    <LazySearchAddressPageSearchAddressFAQ
      title="Вопросы и ответы по подключению"
      :faq-block="faqBlock"
    />

    <!--    <template v-if="!houseUrl || !providersIds || providersIds && provider && providersIds.includes(provider.provider.id)"> -->
    <LazyPartnersPageQuestion
      :color="color"
      :provider="provider"
      class="container"
    />
    <LazyPartnersCallMeBtn />
    <!--    </template> -->
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { getCallNumber } from '~/composible/getCallNumber'
import removeUtm from '~/composible/removeUtm'
import { textAggReplace } from '~/helpers'
import getTextAsync from '~/composible/getPageText'
import { tariffsComposible } from '~/composible/getTariffs'
import { providersComposible } from '~/composible/getProviders'
import fetchTextAgg from '~/composible/getTextAgg'

const ctx = useNuxtApp()
const $gtm = useGtm()
const $route = useRoute()
const $router = useRouter()
const mainStore = useMainStore()
const cityStore = useCities()
const loadingProviders = ref(false)
const fetching = ref(false)
const provider = computed(() => mainStore.getDomainConfig)
const color = computed(() => provider.value?.provider?.config?.color)
const getAddressText = computed(() => cityStore.getAddressText)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const currentCity = computed(() => cityStore.getCity)
const houseUrl = computed(() => cityStore.getHouseUrl)
const segmentation = computed(() => mainStore.getSegmentation)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const addressWOTariffsCookie = useCookie('addressWOTariffs', { maxAge: 60 * 60 * 24 })
const h1 = computed(() =>
  textAsync.value?.h1
    ? textAggReplace(
        textAsync.value.h1,
        getAddressText.value,
        undefined,
        provider.value?.provider.name,
      )
        .replace(/-/g, '\u2011')
        .replace(/\\/g, '\n')
        .replace(/_/g, '&nbsp;')
    : '',
)
const faqBlock = computed(() => textAsync.value?.blocks?.faqs)

const { callNumber, getPhoneNumber, stopGetCall } = getCallNumber()

const options = {
  rootMargin: '100px',
  threshold: 0.1,
}
const checkPosition = (entries) => {
  if (entries && entries[0].isIntersecting) {
    getPhoneNumber(false, undefined, true)
  }
  else {
    stopGetCall()
  }
}

const crumbsItems = computed(() => {
  return [
    {
      to: `/`,
      text: 'home',
    },
  ]
})

const textAsync = computed(() => data.value?.textAsync)
const providersIds = computed(
  () => data.value?.providers?.filter(el => !el.provider.passThroughLeads).map(el => +el?.provider.id),
)
const providerConsumed = computed(
  () => data.value?.providerConsumed?.filter(el => !el.provider.passThroughLeads).map(el => el.provider.id),
)
const tariffsAgg = computed(() => {
  if (houseTariffs.value) return data.value?.providerConsumed?.[0]?.agg
  else return data.value?.cityProvider?.[0]?.agg
})

const houseTariffs = computed(() => {
  return !houseUrl.value
    || !providersIds.value
    || (providersIds.value
      && provider.value
      && (providersIds.value.includes(provider.value.provider.id) || providerConsumed.value?.some(el => providersIds.value.includes(+el))))
})

const tariffs = computed(() => {
  if (houseTariffs.value) return data.value?.tariffs
  else return data.value?.cityTariffs
})
const textAgg = computed(() => data.value?.textAgg)
const { filters, ranges, setFiltersOnMounted, fetchTariffs } = tariffsComposible()
const { fetchProviders } = providersComposible()

let errorStatus
let errorMessage
const [{ data, error }, { data: cities }] = await Promise.all([
  useAsyncData(
    removeUtm($route.fullPath) + (currentCity.value?.fias_id || '') + (houseUrl.value || ''),
    async () => {
      let textAsync
      let tariffs
      let cityTariffs
      let providers
      let cityProvider
      let providerConsumed
      let textAgg

      const textAsyncFetch = async () => {
        try {
          textAsync = await getTextAsync({ url: '/' })
        }
        catch (e) {
          errorStatus = e.response.status
          errorMessage = e.message
          throw createError({
            statusCode: e.response.status,
            fatal: true,
            message: e.message,
          })
        }
        finally {
          //
        }
      }
      const tariffsFetch = async () => {
        if (currentCity.value?.fias_id) {
          tariffs = await fetchTariffs({
            strictTechs: true,
            mode: 'PARTN',
          }, {
            pageSize: 20,
            providerSlugs: provider.value?.provider.slug,
          })
        }
      }
      const cityTariffsFetch = async () => {
        if (houseUrl.value) {
          cityTariffs = await fetchTariffs({
            hash: undefined,
            url: undefined,
            strictTechs: true,
            mode: 'PARTN',
          }, {
            pageSize: 20,
            providerSlugs: provider.value?.provider.slug,
          })
          cityTariffs?.data?.forEach(el => el.fromAddress = false)
        }
      }
      const providersHouseFetch = async () => {
        providers = await fetchProviders()
      }
      const textAggFetch = async () => {
        if (currentCity.value?.fias_id) {
          fetching.value = true
          textAgg = await fetchTextAgg({}, {
            tariffsAggInput2: {
              singleProviderId: provider.value?.provider.id,
            },
            groupInput2: {
              singleProviderId: provider.value?.provider.id,
            },
            groupInput3: {
              sort: 'FAST',
              pageSize: 1,
              singleProviderId: provider.value?.provider.id,
            },
            groupInput4: {
              sort: 'CHEAP',
              pageSize: 1,
              singleProviderId: provider.value?.provider.id,
            },
            groupInput5: {
              sort: 'MOST_TV_CHANNELS',
              pageSize: 1,
              singleProviderId: provider.value?.provider.id,
            },
            groupInput6: {
              sort: 'FAST',
              pageSize: 1,
              singleProviderId: provider.value?.provider.id,
            },
            groupInput7: {
              sort: 'CHEAP',
              pageSize: 1,
              singleProviderId: provider.value?.provider.id,
            },
            groupInput8: {
              sort: 'POPULAR',
              pageSize: 1,
              singleProviderId: provider.value?.provider.id,
            },
          })
        }
      }
      const providersConsumedFetch = async () => {
        providerConsumed = await fetchProviders({
          mode: 'PARTN' }, { slugs: provider.value?.provider.slug })
      }
      const cityProviderFetch = async () => {
        if (currentCity.value?.fias_id) {
          cityProvider = await fetchProviders({
            hash: undefined,
            url: undefined,
            mode: 'PARTN',
          }, { slugs: provider.value?.provider.slug })
        }
      }
      await Promise.all([
        textAsyncFetch(),
        tariffsFetch(),
        cityTariffsFetch(),
        providersHouseFetch(),
        providersConsumedFetch(),
        cityProviderFetch(),
        textAggFetch(),
      ])
      mainStore.archievedTariffs = tariffs?.data?.[0]?.markers?.fake

      return {
        textAsync,
        tariffs,
        cityTariffs,
        providers,
        cityProvider,
        providerConsumed,
        textAgg,
      }
    }),
  useFetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
    query: {
      city_type: 'all',
      sort: 'POPULATION',
      level: 'city',
      limit: 60,
      with_available_smart_filters: false,
      with_provider_slugs: false,
      optimized: true,
      providers: [provider.value?.provider?.id],
    },
    headers: {
      ...getCommonHeaders.value,
    },
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }
      return data
    },
  }),
])

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}
const refetchProviders = async () => {
  if (houseUrl.value) data.value.providers = await fetchProviders({
    mode: 'PARTN' })
  else {
    data.value.providers = undefined
  }
}
const refetchConsumed = async () => {
  data.value.providerConsumed = await fetchProviders({
    mode: 'PARTN' }, { slugs: provider.value?.provider.slug })
}
const refetchTextAgg = async () => {
  data.value.textAgg = await fetchTextAgg({}, {
    tariffsAggInput2: {
      singleProviderId: provider.value?.provider.id,
    },
    groupInput2: {
      singleProviderId: provider.value?.provider.id,
    },
    groupInput3: {
      sort: 'FAST',
      pageSize: 1,
      singleProviderId: provider.value?.provider.id,
    },
    groupInput4: {
      sort: 'CHEAP',
      pageSize: 1,
      singleProviderId: provider.value?.provider.id,
    },
    groupInput5: {
      sort: 'MOST_TV_CHANNELS',
      pageSize: 1,
      singleProviderId: provider.value?.provider.id,
    },
    groupInput6: {
      sort: 'FAST',
      pageSize: 1,
      singleProviderId: provider.value?.provider.id,
    },
    groupInput7: {
      sort: 'CHEAP',
      pageSize: 1,
      singleProviderId: provider.value?.provider.id,
    },
    groupInput8: {
      sort: 'POPULAR',
      pageSize: 1,
      singleProviderId: provider.value?.provider.id,
    },
  })
}
const refetchCityProvider = async () => {
  data.value.cityProvider = await fetchProviders({
    hash: undefined,
    url: undefined,
    mode: 'PARTN',
  }, { slugs: provider.value?.provider.slug })
}
const refetchTariffs = async () => {
  data.value.tariffs = await fetchTariffs({
    strictTechs: true,
    mode: 'PARTN',
  }, {
    pageSize: 20,
    providerSlugs: provider.value?.provider.slug,
  })
  mainStore.archievedTariffs = data.value.tariffs?.data?.[0]?.markers?.fake
}
const refetchCityTariffs = async () => {
  if (houseUrl.value) {
    data.value.cityTariffs = await fetchTariffs({
      hash: undefined,
      url: undefined,
      strictTechs: true,
      mode: 'PARTN',
    }, {
      pageSize: 20,
      providerSlugs: provider.value?.provider.slug,
    })
    data.value.cityTariffs?.data?.forEach(el => el.fromAddress = false)
  }
}

watch(
  () => currentCity.value?.fias_id,
  () => {
    $router.push({ path: `/${currentCity.value?.url}/` })
  },
)

watch(
  () => houseUrl.value,
  async (New) => {
    if (New) $router.push({ path: `/${currentCity.value?.url}/` })
    if (data.value) data.value.providers = await fetchProviders()
    if (!providersIds.value?.length && houseUrl.value) {
      mainStore.$patch({
        addressWOTariffs: true,
      })
      addressWOTariffsCookie.value = segmentation.value
    }
    else if (houseUrl.value) {
      if (mainStore.getSegmentationActive) {
        $gtm.trackEvent({
          event: 'trackEvent',
          category: 'segmentation',
          action: 'unlocked',
        })
      }
      mainStore.$patch({
        oldClient: false,
        addressWOTariffs: false,
        providerClient: undefined,
      })
      oldClientCookie.value = null
      addressWOTariffsCookie.value = null
    }
  },
)

onBeforeMount(async () => {
  if (currentCity.value?.fias_id && !textAgg.value && !fetching.value) {
    fetching.value = true
    await Promise.all([
      refetchProviders(),
      refetchConsumed(),
      refetchTextAgg(),
      refetchCityProvider(),
      refetchTariffs(),
      refetchCityTariffs(),
    ])
  }
})

onMounted(() => {
  setFiltersOnMounted(tariffs.value)
  nextTick(() => {
    let target = document.querySelector('.how-connect')
    const observer = new IntersectionObserver(checkPosition, options)

    if (target) {
      observer.observe(target)
    }
    else {
      setTimeout(() => {
        target = document.querySelector('.how-connect')
        if (target) observer.observe(target)
      }, 1000)
    }
  })
})

useHead(() => ({
  title: () =>
    textAsync.value?.title
      ? textAggReplace(
          textAsync.value.title,
          getAddressText.value,
          textAgg.value,
          provider.value?.provider.name,
        )
      : `${provider.value?.provider.name} ${getAddressText.value.cityOnlyLoc} - Тарифы и услуги интернет-провайдера`,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () =>
        textAsync.value?.description
          ? textAggReplace(
              textAsync.value.description,
              getAddressText.value,
              textAgg.value,
              provider.value?.provider.name,
            )
          : `Какие тарифы на домашний интернет от ${provider.value?.provider.name} лучше провести в квартиру ${getAddressText.value.cityOnlyLoc}? Список  \nвсех тарифов проводного интернета и возможность заказать услугу подключения у нас на сайте`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: textAggReplace(
        h1.value.replace(/-/g, '\u2011').replace(/\\/g, '\n'),
        getAddressText.value,
        textAgg.value,
        provider.value?.provider.name,
      ),
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: provider.value
        ? `https://${provider.value?.domain}/android-icon-192x192.png`
        : 'https://dominternet.ru/android-icon-192x192.png',
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: () =>
        textAggReplace(
          (textAsync.value?.description || ''),
          getAddressText.value,
          textAgg.value,
          provider.value?.provider.name,
        ),
    },
  ],
}))
</script>

<style scoped lang="scss">
.pn-main {
  &:deep(.d-breadcrumbs) {
    opacity: 0;
    max-height: 0;
  }

  &:deep(.pn-hat__img) {
    max-height: 330px;
  }
  &:deep(.pcn-hat__h1) {
    margin-top: 160px;
    margin-bottom: 80px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 96px;
      margin-bottom: 8px;
    }
  }

  .sa-faq {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &:deep(.b-cities) {
    margin-top: 64px;
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(desktop)) {
      margin-top: 48px;
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .b-cities__wrap {
      @media (max-width: getBreakpoint(tablet)) {
        box-shadow: none;
      }
    }
    .b-cities__letters-btn.active {
      border-color: var(--mainColor);
    }
    .b-cities__result-item:hover,
    .b-cities__show-more {
      color: var(--mainColor);
    }
  }

  &:deep(.swiper-btn-wrap) {
    background: linear-gradient(
      90deg,
      rgba(color(gray-p), 0) 0%,
      color(gray-p) 100%
    );
  }

  &__gradient {
    margin-top: 32px;
    background: var(--bg_offer);

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 40px;
    }

    &:deep(.best-tariffs) {
      .best-tariffs__wrap {
        background: var(--bg_form2);
        color: color(black);
        .white--text {
          color: color(black);
        }
        .best-tariffs__submit,
        .best-tariffs__submit:not(:disabled) {
          background: var(--form_btn);
        }
        .el-input {
          background: color(gray-p);
        }
      }
    }
    &:deep(.p-benefits) {
      opacity: 0.7;
      color: color(white);
    }
  }

  &--bgc {
    background: var(--bg_offer);
  }

  &:deep(.d-breadcrumbs) {
    .d-breadcrumbs__item.d-breadcrumbs__item--non-active {
      color: var(--font_offer);
      opacity: 0.5;
      transition: opacity 0.24s;
      &:hover {
        opacity: 1;
      }
    }
    .d-breadcrumbs__item--slash {
      color: var(--font_offer);
      opacity: 0.5;
    }
    .d-breadcrumbs__item.d-breadcrumbs__item--active {
      color: var(--font_offer);
    }
  }

  &:deep(.m-page__faq) {
    margin-top: 64px;
    margin-bottom: 64px;
    h2 {
      margin-bottom: 32px;
    }
  }
}
</style>
